import {
  IDefaultTableColumnConfig,
  IWebsiteProviderConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteProviderConfig implements IWebsiteProviderConfig {
  public reportTitle = 'Website Provider Performance';
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.WebsiteProviderSummaryHelp,
    helpTextTitle: 'Provider Summary',
    exportName: 'Website Provider Performance - Summary Data',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      // Website Visits
      {
        id: Enums.websiteMetrics.totalVisits.metricId,
        displayName: 'Website Visits',
        propertyName: Enums.websiteMetrics.totalVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Unique Visitors
      {
        id: Enums.websiteMetrics.uniqueVisits.metricId,
        displayName: 'Unique Visitors',
        propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Qualified Visits
      {
        id: Enums.websiteMetrics.qualifiedVisits.metricId,
        displayName: 'Qualified Visits',
        propertyName: Enums.websiteMetrics.qualifiedVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Total Page Views
      {
        id: Enums.websiteMetrics.pageViews.metricId,
        displayName: 'Total Page Views',
        propertyName: Enums.websiteMetrics.pageViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // pvpvs aren't in spec but might come in
      // {
      //     id: Enums.websiteMetrics.pageViewsPerVisit.metricId,
      //     displayName: Enums.websiteMetrics.pageViewsPerVisit.name,
      //     propertyName: Enums.websiteMetrics.pageViewsPerVisit.nameKey,
      //     metricFormatKey: constants.formatKeys.roundToHundreth
      // },
      // Time on Site
      {
        id: Enums.websiteMetrics.avgTimeOnSite.metricId,
        displayName: 'Avg Time On Site',
        propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
        metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
      },
      // Engagements
      {
        id: Enums.websiteMetrics.engagements.metricId,
        displayName: Enums.websiteMetrics.engagements.name,
        propertyName: Enums.websiteMetrics.engagements.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Engagement Rate
      {
        id: Enums.websiteMetrics.engagementRate.metricId,
        displayName: Enums.websiteMetrics.engagementRate.name,
        propertyName: Enums.websiteMetrics.engagementRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Actions
      {
        id: Enums.websiteMetrics.actions.metricId,
        displayName: Enums.websiteMetrics.actions.name,
        propertyName: Enums.websiteMetrics.actions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Action Rate
      {
        id: Enums.websiteMetrics.actionRate.metricId,
        displayName: Enums.websiteMetrics.actionRate.name,
        propertyName: Enums.websiteMetrics.actionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Form Submissions
      {
        id: Enums.websiteMetrics.formSubmissions.metricId,
        displayName: Enums.websiteMetrics.formSubmissions.name,
        propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Form Conversion Rate
      {
        id: Enums.websiteMetrics.formConversionRate.metricId,
        displayName: Enums.websiteMetrics.formConversionRate.name,
        propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Phone Calls
      {
        id: Enums.websiteMetrics.phoneCalls.metricId,
        displayName: Enums.websiteMetrics.phoneCalls.name,
        propertyName: Enums.websiteMetrics.phoneCalls.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Phone Conversion Rate
      {
        id: Enums.websiteMetrics.phoneConversionRate.metricId,
        displayName: Enums.websiteMetrics.phoneConversionRate.name,
        propertyName: Enums.websiteMetrics.phoneConversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Total Contacts
      {
        id: Enums.websiteMetrics.totalContacts.metricId,
        displayName: Enums.websiteMetrics.totalContacts.name,
        propertyName: Enums.websiteMetrics.totalContacts.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Total Contact Rate
      {
        id: Enums.websiteMetrics.totalContactRate.metricId,
        displayName: Enums.websiteMetrics.totalContactRate.name,
        propertyName: Enums.websiteMetrics.totalContactRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Click to Call
      {
        id: Enums.websiteMetrics.clickToCalls.metricId,
        displayName: Enums.websiteMetrics.clickToCalls.name,
        propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Bounces
      {
        id: Enums.websiteMetrics.bounces.metricId,
        displayName: Enums.websiteMetrics.bounces.name,
        propertyName: Enums.websiteMetrics.bounces.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Bounce Rate
      {
        id: Enums.websiteMetrics.bounceRate.metricId,
        displayName: Enums.websiteMetrics.bounceRate.name,
        propertyName: Enums.websiteMetrics.bounceRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // New VDPs
      {
        id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.newInventoryDetailViews.name,
        propertyName: Enums.websiteMetrics.newInventoryDetailViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Used VDPs
      {
        id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.usedInventoryDetailViews.name,
        propertyName: Enums.websiteMetrics.usedInventoryDetailViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // CPO VDPs
      {
        id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.cpoInventoryDetailViews.name,
        propertyName: Enums.websiteMetrics.cpoInventoryDetailViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
    ],
    childMetrics: [
      {
        id: 0,
        displayName: Enums.websiteProviders.allProviders.name,
        propertyName: Enums.websiteProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 3,
        displayName: Enums.websiteProviders.dealerOn.name,
        propertyName: 'DealerOn',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 5,
        displayName: 'Ansira',
        propertyName: 'Ansira',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 12,
        displayName: Enums.websiteProviders.dealerInspire.name,
        propertyName: Enums.websiteProviders.dealerInspire.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 14,
        displayName: Enums.websiteProviders.foxDealer.name,
        propertyName: Enums.websiteProviders.foxDealer.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
    ],

    defaultChildrenIds: [Enums.websiteProviders.allProviders.providerId, Enums.websiteProviders.allProviders.providerId],
    defaultParentIds: [Enums.websiteMetrics.engagementRate.metricId, Enums.websiteMetrics.actionRate.metricId],
    metricOneColorHexCode: '#D0573a',
    metricTwoColorHexCode: '#646464'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: 'Provider',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },

    // Total Visits
    {
      show: true,
      header: 'Website Visits',
      columnDef: 'totalVisits',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Website Visits',
      columnDef: 'totalVisitsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Website Visits MOM',
      columnDef: 'totalVisitsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Website Visits',
      columnDef: 'totalVisitsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Website Visits YOY',
      columnDef: 'totalVisitsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Unique Visitors
    {
      show: true,
      header: 'Unique Visitors',
      columnDef: 'uniqueVisits',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Unique Visitors',
      columnDef: 'uniqueVisitsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Unique Visitors MOM',
      columnDef: 'uniqueVisitsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Unique Visitors',
      columnDef: 'uniqueVisitsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Unique Visitors YOY',
      columnDef: 'uniqueVisitsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Qualified Visits
    {
      show: true,
      header: 'Qualified Visits',
      columnDef: 'qualifiedVisits',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Qualified Visits',
      columnDef: 'qualifiedVisitsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Qualified Visits MOM',
      columnDef: 'qualifiedVisitsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Qualified Visits',
      columnDef: 'qualifiedVisitsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Qualified Visits YOY',
      columnDef: 'qualifiedVisitsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Total Page Views
    {
      show: true,
      header: 'Total Page Views',
      columnDef: 'pageViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Total Page Views',
      columnDef: 'pageViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Page Views MOM',
      columnDef: 'pageViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Total Page Views',
      columnDef: 'pageViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Page Views YOY',
      columnDef: 'pageViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Page Views per visit
    // {
    //     show: true, header: 'Page Views Per Visit',
    //     columnDef: 'pageViewsPerVisit',
    //     isMom: false,
    //     isYoy: false,
    //     metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
    // },
    // {
    //     show: false, header: 'Previous Month Page Views Per Visit',
    //     columnDef: 'pageViewsPerVisitPreviousMTD',
    //     isMom: true,
    //     isYoy: false,
    //     metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
    // },
    // {
    //     show: false, header: 'Page Views Per Visit MOM',
    //     columnDef: 'pageViewsPerVisitMOM',
    //     isMom: true,
    //     isYoy: false,
    //     metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    // },
    // {
    //     show: false, header: 'Previous Year Page Views Per Visit',
    //     columnDef: 'pageViewsPerVisitPreviousYear',
    //     isMom: false,
    //     isYoy: true,
    //     metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
    //  },
    // {
    //     show: false, header: 'Page Views Per Visit YOY',
    //     columnDef: 'pageViewsPerVisitYOY',
    //     isMom: false,
    //     isYoy: true,
    //     metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    // },

    // Avg. Time on Site
    {
      show: true,
      header: 'Avg Time on Site',
      columnDef: 'averageTimeOnSite',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Avg Time on Site',
      columnDef: 'averageTimeOnSitePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
    },
    {
      show: false,
      header: 'Avg Time on Site MOM',
      columnDef: 'averageTimeOnSiteMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Avg Time on Site',
      columnDef: 'averageTimeOnSitePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
    },
    {
      show: false,
      header: 'Avg Time on Site YOY',
      columnDef: 'averageTimeOnSiteYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Engagements
    {
      show: true,
      header: 'Engagements',
      columnDef: 'engagements',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Engagements',
      columnDef: 'engagementsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Engagements MOM',
      columnDef: 'engagementsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Engagements',
      columnDef: 'engagementsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Engagements YOY',
      columnDef: 'engagementsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Engagement Rate
    {
      show: true,
      header: 'Engagement Rate',
      columnDef: 'engagementRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Engagement Rate',
      columnDef: 'engagementRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Engagement Rate MOM',
      columnDef: 'engagementRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Engagement Rate',
      columnDef: 'engagementRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Engagement Rate YOY',
      columnDef: 'engagementRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Actions
    {
      show: true,
      header: 'Actions',
      columnDef: 'actions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Actions',
      columnDef: 'actionsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Actions MOM',
      columnDef: 'actionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Actions',
      columnDef: 'actionsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Actions YOY',
      columnDef: 'actionsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Action Rate
    {
      show: true,
      header: 'Action Rate',
      columnDef: 'actionRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Action Rate',
      columnDef: 'actionRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Action Rate MOM',
      columnDef: 'actionRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Action Rate',
      columnDef: 'actionRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Action Rate YOY',
      columnDef: 'actionRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Form Submissions',
      columnDef: 'formSubmissionsPreviousMTD',
      isMom: true, isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Form Submissions MOM',
      columnDef: 'formSubmissionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Form Submissions',
      columnDef: 'formSubmissionsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Form Submissions YOY',
      columnDef: 'formSubmissionsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Form Conversion Rate
    {
      show: true,
      header: 'Form Conversion Rate',
      columnDef: 'formConversionRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Form Conversion Rate',
      columnDef: 'formConversionRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Form Conversion Rate MOM',
      columnDef: 'formConversionRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Form Conversion Rate',
      columnDef: 'formConversionRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Form Conversion Rate YOY',
      columnDef: 'formConversionRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Phone Calls
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneCalls',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Phone Calls',
      columnDef: 'phoneCallsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Phone Calls MOM',
      columnDef: 'phoneCallsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Phone Calls',
      columnDef: 'phoneCallsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Phone Calls YOY',
      columnDef: 'phoneCallsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Phone Conversion Rate
    {
      show: true,
      header: 'Phone Conversion Rate',
      columnDef: 'phoneConversionRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Phone Conversion Rate',
      columnDef: 'phoneConversionRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Phone Conversion Rate MOM',
      columnDef: 'phoneConversionRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Phone Conversion Rate',
      columnDef: 'phoneConversionRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Phone Conversion Rate YOY',
      columnDef: 'phoneConversionRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Total Contacts
    {
      show: true,
      header: 'Total Contacts',
      columnDef: 'totalContacts',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Total Contacts',
      columnDef: 'totalContactsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Contacts MOM',
      columnDef: 'totalContactsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Total Contacts',
      columnDef: 'totalContactsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Contacts YOY',
      columnDef: 'totalContactsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Total Contact Rate
    {
      show: true,
      header: 'Total Contact Rate',
      columnDef: 'totalContactRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Total Contact Rate',
      columnDef: 'totalContactRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Total Contact Rate MOM',
      columnDef: 'totalContactRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Total Contact Rate',
      columnDef: 'totalContactRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Total Contact Rate YOY',
      columnDef: 'totalContactRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Click to Call
    {
      show: true,
      header: 'Click to Calls',
      columnDef: 'clickToCalls',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Click to Calls',
      columnDef: 'clickToCallsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Click to Calls MOM',
      columnDef: 'clickToCallsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Click to Calls',
      columnDef: 'clickToCallsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Click to Calls YOY',
      columnDef: 'clickToCallsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Click to Call
    {
      show: true,
      header: 'Bounces',
      columnDef: 'bounces',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Bounces',
      columnDef: 'bouncesPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Bounces MOM',
      columnDef: 'bouncesMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Bounces',
      columnDef: 'bouncesPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Bounces YOY',
      columnDef: 'bouncesYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Month Bounce Rate',
      columnDef: 'bounceRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Bounce Rate MOM',
      columnDef: 'bounceRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year Bounce Rate',
      columnDef: 'bounceRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Bounce Rate YOY',
      columnDef: 'bounceRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // New VDPs
    {
      show: true,
      header: 'New Inventory Detail Views',
      columnDef: 'newInventoryDetailViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month New Inventory Detail Views',
      columnDef: 'newInventoryDetailViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'New Inventory Detail Views MOM',
      columnDef: 'newInventoryDetailViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year New Inventory Detail Views',
      columnDef: 'newInventoryDetailViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false, header: 'New Inventory Detail Views YOY',
      columnDef: 'newInventoryDetailViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Used VDPs
    {
      show: true, header: 'Used Inventory Detail Views',
      columnDef: 'usedInventoryDetailViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false, header: 'Previous Month Used Inventory Detail Views',
      columnDef: 'usedInventoryDetailViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false, header: 'Used Inventory Detail Views MOM',
      columnDef: 'usedInventoryDetailViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false, header: 'Previous Year Used Inventory Detail Views',
      columnDef: 'usedInventoryDetailViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Used Inventory Detail Views YOY',
      columnDef: 'usedInventoryDetailViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // CPO VDPs
    {
      show: true,
      header: 'CPO Inventory Detail Views',
      columnDef: 'cpoInventoryDetailViews',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Previous Month CPO Inventory Detail Views',
      columnDef: 'cpoInventoryDetailViewsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'CPO Inventory Detail Views MOM',
      columnDef: 'cpoInventoryDetailViewsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Previous Year CPO Inventory Detail Views',
      columnDef: 'cpoInventoryDetailViewsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'CPO Inventory Detail Views YOY',
      columnDef: 'cpoInventoryDetailViewsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    }
  ];
}
