import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
      Enums.dateModes.currentMonth,
      Enums.dateModes.lastMonth,
      Enums.dateModes.lastThirtyDays,
      Enums.dateModes.previousThreeMonths,
      Enums.dateModes.previousTwelveMonths
    ]
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
      'org': { displayName: 'Hierarchy', name: 'org' },
      'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
      'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
      'device-type': { displayName: 'Device Type', name: 'device-type' },
      'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
      'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
      'source-type': { displayName: 'Source Type', name: 'source-type' },
      'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
      'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
      'website-provider': { displayName: 'Provider', name: 'website-provider' },
      'channel': { displayName: 'Channel', name: 'channel' },
      'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
      'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
      'digad-program': { displayName: 'Program', name: 'digad-program' },
      'vehicle-type': { displayName: 'Vehicle Type', name: 'vehicle-type' },
      'deal-type': { displayName: 'Deal Type', name: 'deal-type' },
      'lead-gate': { displayName: 'Lead Gate', name: 'lead-gate' }
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Leads
        'LeadsOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.leadsOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'leads'
        },
        'LeadSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadSummary,
            filters: [
              'org', 'lead-type', 'source-type', 'vehicle-model'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadSourcePerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadSourcePerformance,
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-model'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadVehiclePerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadVehiclePerformance,
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-model'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadTransactions': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadTransactions,
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-model'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        // Sales
        'SalesOverview': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.salesOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'sales',
            orgTypes: [1]
        },
        'SalesSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.salesSummary,
            filters: [
                'org', 'sale-type', 'lead-type', 'source-type',
            ],
            defaults: [
                'org', 'sale-type', 'lead-type', 'source-type'
            ],
            section: 'sales',
            orgTypes: [1]
        },
        //'SalesSourceSummary': {
        //    dateMode: Enums.dateModes.currentMonth,
        //    quarterDateModeEnabled: true,
        //    reportName: 'SalesSourceSummary',
        //    filters: [
        //        'org', 'lead-type'
        //    ],
        //    defaults: [
        //        'org', 'lead-type'
        //    ],
        //    section: 'sales',
        //    orgTypes: [1],
        //    defaultMonthDateMode: 'sales'
        //},
        'SalesVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.salesVehicleSummary,
            filters: [
                'org', 'lead-type', 'sale-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'sale-type', 'source-type'
            ],
            section: 'sales',
            orgTypes: [1],
            defaultMonthDateMode: 'sales'
        },
        // Website
        'WebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteScorecard,
            filters: [
              'org', 'website-provider'
            ],
            defaults: [
              'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteSummary,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteReferrerSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteReferrerSummary,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteVehicleSummary,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteProviderSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteProviderSummary, // org only
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1]
        },
        // DigAd
        'DigitalAdvertisingOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingOverview,
            filters: ['org', 'channel', 'digad-program'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingScorecard,
            filters: ['org', 'channel', 'digad-provider', 'digad-program'],
            defaults: ['org', 'channel', 'digad-provider'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingSummary,
            filters: ['org', 'channel', 'digad-provider', 'digad-program'],
            defaults: ['org', 'channel', 'digad-provider'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingProvider': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingProvider,
            filters: ['org', 'channel', 'digad-program'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingChannel': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingChannel,
            filters: ['org', 'digad-provider', 'digad-program'],
            defaults: ['org', 'digad-provider'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        // Provider Tools
        'DigitalRetailingOverview': {
            reportName: constants.reportNames.digitalRetailingOverview,
            dateMode: Enums.dateModes.currentMonth,
            filters: ['org', 'device-type', 'digital-retailing-provider', 'vehicle-type', 'deal-type', 'lead-gate' ],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1]
        },
        //   Chat
        'ChatOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.chatOverview,
            filters: ['org', 'device-type', 'chat-provider'],
            defaults: ['org'],
            section: 'chat',
            orgTypes: [1]
        },
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'chat-provider': {
            type: 'chat-provider',
            available: [12,15,18,19,20],
            selected: [
                { value: 15, display: 'CarNow' },
                { value: 18, display: 'ActivEngage' },
                { value: 12, display: 'Dealer Inspire' },
                { value: 19, display: 'Gubagoo' },
                { value: 20, display: 'Podium' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            selected: [{ value: 1, display: 'New Sales' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            selected: [{ value: 1, display: 'New' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            available: [1,2,3,5,7,8],
            selected: [
                { value: 1, display: 'Brand' },
                { value: 2, display: '3rd Party' },
                { value: 3, display: 'Retailer Website' },
                { value: 5, display: 'Event' },
                { value: 7, display: 'E-Commerce' },
                { value: 8, display: 'Chat' },
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [3, 5, 12, 14],
            selected: [
              { value: 3, display: 'DealerOn' },
              { value: 5, display: 'Ansira' },
              { value: 12, display: 'Dealer Inspire' },
              { value: 14, display: 'Fox Dealer' },
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'channel': {
            type: 'channel',
            available: [13,16,14,4,2,8,5,21,15],
            selected: [
                { value: 13, display: 'ATV' },
                { value: 16, display: 'Mobile Gaming' },
                { value: 14, display: 'OTT' },
                { value: 4, display: 'Paid Display' },
                { value: 2, display: 'Paid Search' },
                { value: 8, display: 'Paid Social' },
                { value: 5, display: 'Paid Video' },
                { value: 21, display: 'Performance Max' },
                { value: 15, display: 'Streaming Audio' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
      'digad-provider': {
        type: 'digad-provider',
        available: [ 3,5,11,14,21,22,23 ],
        selected: [
          { value: 3, display: 'DealerON' },
          { value: 5, display: 'Ansira' },
          { value: 11, display: 'SoKal' },
          { value: 14, display: 'Fox Dealer' },
          { value: 21, display: 'Constellation' },
          { value: 22, display: 'Stream' },
          { value: 23, display: 'Force Marketing' }
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'digital-retailing-provider': {
        type: 'digital-retailing-provider',
        available: [15,16,17,19,25],
        selected: [
          { value: 15, display: 'CarNow' },
          { value: 16, display: 'Roadster' },
          { value: 17, display: 'TagRail' },
          { value: 19, display: 'Gubagoo' },
          { value: 25, display: 'Darwin Automotive' }
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'digad-program': {
        type: 'digad-program',
        available: [],
        selected: [
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'vehicle-type': {
        type: 'vehicle-type',
        available: [1, 2, 3],
        selected: [
          { value: 1, display: 'New' },
          { value: 2, display: 'Used' },
          { value: 3, display: 'CPO' },
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'deal-type': {
        type: 'deal-type',
        available: [1, 2,3,4],
        selected: [
          { value: 1, display: 'Finance' },
          { value: 2, display: 'Lease' },
          { value: 3, display: 'Cash' },
          { value: 4, display: 'Other' },
        ],
        removable: true,
        lockable: true,
        locked: false
      },
      'lead-gate': {
        type: 'lead-gate',
        available: [1, 2],
        selected: [
          { value: 1, display: 'Enabled' },
          { value: 2, display: 'Disabled' },
        ],
        removable: true,
        lockable: true,
        locked: false
      }
    };
}
